import { createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@public/img/close-btn.png'


const _hoisted_1 = {
  class: "modal prize-modal",
  role: "dialog"
}
const _hoisted_2 = { class: "header-logo" }
const _hoisted_3 = ["src", "alt"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "sblix-img" }
const _hoisted_7 = ["src", "alt"]
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = ["alt"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    class: "modal-overlay",
    onClick: _cache[1] || (_cache[1] = _withModifiers($event => (_ctx.closeModal()), ["self"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("img", {
          src: _ctx.$i18nAsset('img/footlong-season.svg'),
          alt: $setup.t('global:promo_logo_alt')
        }, null, 8, _hoisted_3)
      ]),
      _createElementVNode("h2", {
        innerHTML: $setup.t('prize_details.headline_copy_1')
      }, null, 8, _hoisted_4),
      _createElementVNode("h2", {
        innerHTML: $setup.t('prize_details.headline_copy_2')
      }, null, 8, _hoisted_5),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("img", {
          src: _ctx.$i18nAsset('img/hub-sponsor-logos.png'),
          alt: $setup.t('global:sponsor_event_alt')
        }, null, 8, _hoisted_7)
      ]),
      _createElementVNode("p", {
        class: "prize_details_1",
        innerHTML: $setup.t('prize_details.body_copy_1')
      }, null, 8, _hoisted_8),
      _createElementVNode("h2", {
        innerHTML: $setup.t('prize_details.headline_copy_3')
      }, null, 8, _hoisted_9),
      _createElementVNode("p", {
        innerHTML: $setup.t('prize_details.body_copy_2', { rulesLink: $options.rulesLink, interpolation: { escapeValue: false } })
      }, null, 8, _hoisted_10),
      _createElementVNode("a", {
        class: "close-btn",
        href: "#",
        "data-e2e": "prize-detail-modal-close",
        onClick: _cache[0] || (_cache[0] = _withModifiers($event => (_ctx.closeModal()), ["prevent"]))
      }, [
        _createElementVNode("img", {
          src: _imports_0,
          alt: $setup.t('buttons:close_title')
        }, null, 8, _hoisted_11)
      ])
    ])
  ]))
}