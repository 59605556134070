import { createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["src", "alt"]
const _hoisted_2 = { class: "disclaimer" }
const _hoisted_3 = { class: "footer-links" }
const _hoisted_4 = ["href", "title"]
const _hoisted_5 = ["href", "title"]
const _hoisted_6 = ["href", "title"]
const _hoisted_7 = { class: "log-out-wrapper" }
const _hoisted_8 = {
  key: 0,
  "data-e2e": "footer-logged-in"
}
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = { class: "disclaimer" }
const _hoisted_11 = {
  key: 0,
  class: "language-toggle-wrapper"
}
const _hoisted_12 = ["href"]
const _hoisted_13 = ["href"]
const _hoisted_14 = {
  key: 1,
  class: "artificial-tests"
}
const _hoisted_15 = ["textContent"]
const _hoisted_16 = ["src"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _directive_t = _resolveDirective("t")

  return (_openBlock(), _createElementBlock("footer", {
    class: _normalizeClass(["footer", {
            'mentions-subway-for-a-year': $options.mentionsSubwayForAYear,
            'should-show-purchase-disclaimer': $options.shouldShowPuchaseDisclaimer,
        }])
  }, [
    _createElementVNode("img", {
      src: _ctx.$i18nAsset('img/footer-logos.png'),
      alt: $setup.t('footer:logo_alt_copy'),
      class: "footer-logos"
    }, null, 8, _hoisted_1),
    _withDirectives(_createElementVNode("p", _hoisted_2, null, 512), [
      [_directive_t, 'footer:official_sponsor']
    ]),
    _createElementVNode("nav", _hoisted_3, [
      _createElementVNode("ul", null, [
        _createElementVNode("li", null, [
          _createElementVNode("a", {
            href: $options.rulesUrl,
            target: "_blank",
            title: `${$setup.t('links:rules_copy')} ${$setup.t('links:new_window_rules')}`
          }, _toDisplayString($setup.t('links:rules_copy')), 9, _hoisted_4)
        ]),
        _createElementVNode("li", null, [
          _createElementVNode("a", {
            href: $setup.t('links:privacy_url'),
            target: "_blank",
            title: `${$setup.t('links:privacy_copy')} ${$setup.t('links:new_window')}`
          }, _toDisplayString($setup.t('links:privacy_copy')), 9, _hoisted_5)
        ]),
        _createElementVNode("li", null, [
          _createElementVNode("a", {
            href: `/faq?locale=${_ctx.app.locale}`,
            title: `${$setup.t('links:faq_copy')} ${$setup.t('links:popup')}`,
            onClick: _cache[0] || (_cache[0] = _withModifiers((...args) => ($options.openPopup && $options.openPopup(...args)), ["prevent"]))
          }, _toDisplayString($setup.t('links:faq_copy')), 9, _hoisted_6)
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        (_ctx.loggedIn)
          ? (_openBlock(), _createElementBlock("span", _hoisted_8, [
              _createTextVNode(_toDisplayString($setup.t(`footer:logged_in_copy`, { firstName: _ctx.profile.first_name })) + " (" + _toDisplayString($setup.t(`footer:not_you`)) + " ", 1),
              _withDirectives(_createElementVNode("a", {
                href: "/",
                class: "btn_logout",
                "data-e2e": "footer-logout",
                onClick: _cache[1] || (_cache[1] = (...args) => (_ctx.logOut && _ctx.logOut(...args)))
              }, null, 512), [
                [_directive_t, 'footer:log_out']
              ]),
              _cache[2] || (_cache[2] = _createTextVNode(") "))
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", {
      class: "disclaimer",
      innerHTML: $options.disclaimerText
    }, null, 8, _hoisted_9),
    _createElementVNode("div", _hoisted_10, [
      _withDirectives(_createElementVNode("span", null, null, 512), [
        [_directive_t, 'footer:recapcha_disclaimer']
      ]),
      _withDirectives(_createElementVNode("span", null, null, 512), [
        [_directive_t, 'footer:subway_trademark_disclaimer']
      ])
    ]),
    (_ctx.app.userCountry.toLowerCase() == 'ca')
      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
          _createElementVNode("a", {
            class: "language-toggle--english",
            href: `${_ctx.app.siteURL}?locale=en-CA`
          }, "[" + _toDisplayString($setup.t('footer:language.english')) + "]", 9, _hoisted_12),
          _createElementVNode("a", {
            class: "language-toggle--french",
            href: `${_ctx.app.siteURL}?locale=fr-CA`
          }, "[" + _toDisplayString($setup.t('footer:language.french')) + "]", 9, _hoisted_13)
        ]))
      : _createCommentVNode("", true),
    (_ctx.app.isAutomatedTest)
      ? (_openBlock(), _createElementBlock("p", _hoisted_14, [
          _createElementVNode("span", {
            class: "test-copy",
            textContent: _toDisplayString($setup.t('testNamespace:testKey'))
          }, null, 8, _hoisted_15),
          _createElementVNode("img", {
            class: "language-flag",
            alt: "Language flag",
            src: _ctx.$i18nAsset('img/flag.svg'),
            style: {"display":"inline","width":"2em","padding":"0 0.5em","vertical-align":"middle"}
          }, null, 8, _hoisted_16)
        ]))
      : _createCommentVNode("", true)
  ], 2))
}