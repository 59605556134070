
    import { useI18next } from '@composables/i18next';
    import { mapActions, mapState } from 'vuex';
    import LockUp from '../components/LockUp.vue';
    import BaseModal from './BaseModal';

    export default {
        name: 'ClearedBoardModal',

        components: {
            LockUp,
        },

        extends: BaseModal,

        props: {
            moveCount: {
                required: false,
                type: Number,
                default: 10,
            },
            images: {
                default: () => ['', ''],
                required: false,
                type: Array,
            },
            showMoveCount: {
                default: false,
                type: Boolean,
                required: false,
            },
        },

        setup () {
            const { t, vT } = useI18next(['modal_content', 'game']);
            return { t, vT };
        },

        data () {
            return {
                modalName: 'cleared-board',
                suppressed: false,
                gamePlayed: false,
            };
        },

        computed: {
            ...mapState(['profile']),
        },

        methods: {
            ...mapActions({
                setChipAnimation: 'ui/setChipAnimation',
                getProfile: 'profile/getProfile',
                setLanguage: 'profile/setLanguage',
            }),
            async amIWinnerClicked () {
                this.suppressed = true;
                await this.playGame();
                this.setChipAnimation(true);
                const languageBeforeReload = this.profile.subway_language;
                await this.getProfile();
                this.setLanguage(languageBeforeReload.split('-')[0]);
                await new Promise(resolve => setTimeout(resolve, 3500));
                this.closeModal();
            },
            async playGame () {
                if (this.gamePlayed) return;

                try {
                    await this.$store.dispatch('award/play');

                    this.gamePlayed = true;
                }
                catch (err) {
                    console.error(err);
                    // throw err;
                }
            },
        },
    };
