
    import { useI18next } from '@composables/i18next';
    import BaseModal from './BaseModal';

    export default {
        name: 'PrizeDetailModal',

        extends: BaseModal,

        setup () {
            const { t, vT } = useI18next(['modal_content', 'links']);
            return { t, vT };
        },
        data () {
            return {
                modalName: 'prize-detail',
            };
        },
        computed: {
            rulesLink () {
                return `<a href="${this.app.tb2URL}${this.t('links:rules_url')}" target="_blank"  title="${this.$t('links:rules_copy')} ${this.$t('links:new_window_rules')}">${this.$t('links:rules_copy')}</a>`;
            },
        },
    };
