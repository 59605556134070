import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "header",
  role: "banner",
  "data-e2e": "global-header"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_LockUp = _resolveComponent("LockUp")

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("h1", {
      ref: "h1",
      class: _normalizeClass({ hidden: $options.hidden }),
      tabindex: "-1"
    }, [
      _createVNode(_component_LockUp)
    ], 2)
  ]))
}