import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"
import _imports_0 from '@public/img/trophy.png'


const _hoisted_1 = {
  ref: "back",
  class: "back-chips"
}
const _hoisted_2 = {
  key: 0,
  class: "between-image"
}
const _hoisted_3 = {
  ref: "front",
  class: "front-chips"
}
const _hoisted_4 = { class: "subway-pill" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["chip-fetti", { animating: $options.animating }]),
    style: _normalizeStyle({ 'animation-duration': `${$data.animationDuration / 1000}s` })
  }, [
    _createElementVNode("div", _hoisted_1, null, 512),
    ($options.didWin)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[0] || (_cache[0] = [
          _createElementVNode("img", { src: _imports_0 }, null, -1)
        ])))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, null, 512),
    _createElementVNode("p", _hoisted_4, _toDisplayString($options.resultCopy), 1)
  ], 6))
}