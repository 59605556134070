import { createElementVNode as _createElementVNode, withModifiers as _withModifiers, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@public/img/close-btn.png'


const _hoisted_1 = {
  class: "modal join-now-modal",
  role: "dialog"
}
const _hoisted_2 = ["alt"]
const _hoisted_3 = { class: "button-wrapper" }
const _hoisted_4 = ["href", "title"]
const _hoisted_5 = { class: "disclaimer-copy" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _directive_t = _resolveDirective("t")

  return (_openBlock(), _createElementBlock("div", {
    class: "modal-overlay",
    onClick: _cache[1] || (_cache[1] = _withModifiers($event => (_ctx.closeModal()), ["self"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("a", {
        class: "close-btn",
        href: "#",
        "data-e2e": "join-now-modal-close",
        onClick: _cache[0] || (_cache[0] = _withModifiers($event => (_ctx.closeModal()), ["prevent"]))
      }, [
        _createElementVNode("img", {
          src: _imports_0,
          alt: $setup.t('buttons:close_title')
        }, null, 8, _hoisted_2)
      ]),
      _withDirectives(_createElementVNode("h2", null, null, 512), [
        [_directive_t, 'join_rewards.headline_copy']
      ]),
      _withDirectives(_createElementVNode("div", null, null, 512), [
        [_directive_t, 'join_rewards.body_copy']
      ]),
      _createElementVNode("div", _hoisted_3, [
        _withDirectives(_createElementVNode("a", {
          class: "btn join-now-btn",
          href: $setup.t('links:join_now'),
          title: `${$setup.t('buttons:join_now')} ${$setup.t('links:new_window')}`,
          target: "_blank"
        }, null, 8, _hoisted_4), [
          [_directive_t, 'buttons:join_now']
        ])
      ]),
      _withDirectives(_createElementVNode("p", _hoisted_5, null, 512), [
        [_directive_t, 'join_rewards.mvp_disclaimer']
      ])
    ])
  ]))
}