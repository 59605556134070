import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "lock-up" }
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = ["src", "alt"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      class: "rewards-image",
      src: $options.mvpLogo,
      alt: $setup.t('global:mvp_logo_alt')
    }, null, 8, _hoisted_2),
    _createElementVNode("img", {
      class: "promo-image",
      src: $options.promoLogo,
      alt: $setup.t('global:promo_logo_alt')
    }, null, 8, _hoisted_3)
  ]))
}