
    import { useI18next } from '@composables/i18next';

    export default {

        props: {
            images: {
                default: () => ['', ''],
                required: false,
                type: Array,
            },
        },

        setup () {
            const { t, vT } = useI18next(['global']);
            return { t, vT };
        },

        computed: {
            mvpLogo () {
                return this.$i18nAsset ? this.$i18nAsset('img/sw-mvp-rewards-white.svg') : this.images[0];
            },
            promoLogo () {
                return this.$i18nAsset ? this.$i18nAsset('img/footlong-season.svg') : this.images[1];
            },
        },
    };
