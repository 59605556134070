
    import { useI18next } from '@composables/i18next';
    import { mapState, mapGetters, mapActions } from 'vuex';
    import { openPopup } from '../popup';

    export default {
        name: 'TheFooter',

        setup () {
            const { t, vT } = useI18next(['footer', 'links', 'global']);
            return { t, vT };
        },

        computed: {
            ...mapState([
                'app',
                'profile',
            ]),
            ...mapGetters({
                loggedIn: 'profile/loggedIn',
            }),
            rulesUrl () {
                return `${this.app.tb2URL}${this.t('links:rules_url')}`;
            },
            disclaimerText () {
                return this.t('footer:disclaimer', { rulesUrl: this.rulesUrl });
            },
            mentionsSubwayForAYear () {
                return ['/faq'].includes(this.$route.path);
            },
            shouldShowPuchaseDisclaimer () {
                return !['pre-launch'].includes(this.app.phase);
            },
        },

        methods: {
            openPopup,

            ...mapActions({
                logOut: 'profile/logOut',
            }),
        },
    };

