
    import { useI18next } from '@composables/i18next';
    import BaseModal from './BaseModal';

    export default {
        name: 'JoinNowModal',

        extends: BaseModal,

        setup () {
            const { t, vT } = useI18next(['modal_content', 'links', 'buttons']);
            return { t, vT };
        },
        data () {
            return {
                modalName: 'join-now',
            };
        },
    };
