
import { CustomMeshController, degreesToRadians, phongImageMaterial } from '../3d-engine';

export default class ChipController extends CustomMeshController {
    constructor (data, model, stats, deltaHandler) {
        super(data, model);
        this.chipModel = null;
        this.startFalling = false;
        this.stats = stats;
        this.deltaHandler = deltaHandler;
        this.fallRate = stats.plane === 'back' ? .00125 : .0035;
        this.rotationRates = [...new Array(3).keys()].map(() => degreesToRadians((Math.random() * 2) - 1));
        this.startRotation = [...new Array(3).keys()].map(() => degreesToRadians((Math.random() * 720) - 360));
        this.shouldRemove = false;
    }

    modelLoaded (model) {
        const scale = .1;
        // const chipMat = basicColorMaterial('ccff66');
        // const chipMat = phongImageMaterial(require('../../img/chipTexture.jpg'));
        const chipMat = phongImageMaterial('https://eprize-content.s3.amazonaws.com/three_chipfetti_demo/media/chipTexture.b64abc085e4a59f4852b.jpg');

        chipMat.shininess = 0;

        this.chipModel = model;
        this.chipModel.children[0].material = chipMat;
        this.chipModel.scale.z = scale;
        this.chipModel.scale.x = scale;
        this.chipModel.scale.y = scale;
        const spacing = .3;
        this.chipModel.position.x = this.stats.x;
        this.chipModel.position.y = this.stats.y - (Math.random() * .75);
        this.chipModel.position.z = this.stats.z;
        this.chipModel.rotateX(this.startRotation[0]);
        this.chipModel.rotateY(this.startRotation[1]);
        this.chipModel.rotateZ(this.startRotation[2]);
        this.removeIfSetToRemove();
    }

    update () {
        if (this.chipModel && this.chipModel?.parent && this.startFalling) {
            this.chipModel.position.y = this.chipModel.position.y - (this.fallRate * (this.environment.timeDelta / 8.5));
            this.chipModel.rotateX(this.rotationRates[0] * (this.environment.timeDelta / 8.5));
            this.chipModel.rotateY(this.rotationRates[1] * (this.environment.timeDelta / 8.5));
            this.chipModel.rotateZ(this.rotationRates[2] * (this.environment.timeDelta / 8.5));
            this.removeIfSetToRemove();
        }
        if (this.deltaHandler) {
            this.deltaHandler(this.environment.timeDelta);
        }
    }

    reset () {
        this.chipModel.position.y = 1.5 + (Math.random() * .25);
    }

    removeIfSetToRemove () {
        if (this.shouldRemove) {
            this.chipModel?.parent?.remove(this.chipModel);


        }

    }
}
