import { BaseScene, LightController, LightTypes, resizeCanvas } from '../3d-engine';
import ChipController from './ChipController';

export default class ChipScene extends BaseScene {
    constructor (el, config, deltaHandler) {
        super(el);
        this.chipControllers = [];
        this.deltaHandler = deltaHandler;

        const lc = new LightController({ environment: this.environment });
        lc.addLight({ type: LightTypes.DIRECTIONAL, intensity: 2.5, target: { x: 0, y: -10, z: 5 }, color: 0xffcc66 });
        lc.addLight({ type: LightTypes.DIRECTIONAL, intensity: .8, target: { x: 0, y: 4, z: -6 }, color: 0xffcc66 });
        config.forEach((item, index) => {
            this.addChip(item, index);
        });
    }

    addChip (stats, index) {
        const chipIndex = Math.floor(Math.random() * 5);
        const model = `https://eprize-content.s3.amazonaws.com/subway/footlongseason/3d-models/potatoChip4.${chipIndex >= 5 ? 4 : chipIndex}.glb`;
        const chipController = new ChipController({ environment: this.environment }, model, stats, index === 0 ? this.deltaHandler : null);
        this.chipControllers.push(chipController);
    }

    getWidth () {
        return 640 * 2;
    }
    getHeight () {
        return 1200 * 2;
    }

    resize ({ width, height }) {
        resizeCanvas(this.environment, width, height);
    }

    animate () {
        this.chipControllers.forEach(item => {
            item.startFalling = true;
        });
    }

    reset () {
        this.chipControllers.forEach(item => {
            item.reset();
            item.startFalling = false;
        });
    }

    removeExtraChips () {
        this.chipControllers.forEach((item, index) => {
            if (index % 3 !== 0) {
                item.shouldRemove = true;
            }
        });
    }

}
