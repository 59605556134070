import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '@public/img/menu-match.png'


const _hoisted_1 = {
  class: "modal cleared-board-modal",
  role: "dialog"
}
const _hoisted_2 = ["alt"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_LockUp = _resolveComponent("LockUp")

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["modal-overlay", { suppressed: $data.suppressed }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h2", null, [
        _createVNode(_component_LockUp, { images: $props.images }, null, 8, ["images"])
      ]),
      _createElementVNode("img", {
        class: "game-image",
        src: _imports_0,
        alt: $setup.t('game:headline_copy')
      }, null, 8, _hoisted_2),
      ($props.showMoveCount)
        ? (_openBlock(), _createElementBlock("h3", {
            key: 0,
            innerHTML: $setup.t('game:board_cleared.headline_copy', { moveCount: $props.moveCount })
          }, null, 8, _hoisted_3))
        : _createCommentVNode("", true),
      _createElementVNode("p", {
        innerHTML: $setup.t('game:board_cleared.body_copy')
      }, null, 8, _hoisted_4),
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = (...args) => ($options.amIWinnerClicked && $options.amIWinnerClicked(...args)))
      }, _toDisplayString($setup.t('game:board_cleared.button')), 1)
    ])
  ], 2))
}